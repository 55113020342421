import React from 'react';
import { CenteredSectionSmall, PageContents } from '../Home/styled';
import { Hero, HeroContents, HeroBadge, HeroTitle, HeroDescription, DownIcon, GreyPlaceholder, TutorialCard, TutorialHeader, TutorialCardOverlay, HowItWorksHeader, HowItWorksRow, WhiteHeader, PaddedText } from '../HowItWorks/styled';
import Header from '../../layout/Header';
import down from '../../images/down.svg';
import { EmailForm } from './EmailForm';

const ConnectRoot = () => {
	return (
		<React.Fragment>
			<Hero>
				<Header />
				<HeroContents>
					<HeroBadge>Let's Get In Touch</HeroBadge>
					<HeroTitle>Connect</HeroTitle>
					<HeroDescription>Leave us a message and we'll get back to you soon as we can. We are always here to help!</HeroDescription>
					<DownIcon src={down} alt="Scroll Down" />
				</HeroContents>
			</Hero>
			<PageContents>
				<CenteredSectionSmall style={{ margin: "64px auto" }}>
					<EmailForm />
				</CenteredSectionSmall>
			</PageContents>
		</React.Fragment>
	);
};

export default ConnectRoot;
