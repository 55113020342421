import React from 'react'
import ConnectRoot from '../views/Connect/ConnectRoot'
import Layout from '../layout/Layout'
import { Seo } from '../components/Seo'

const ConnectPage = () => (
  <Layout>
    <Seo
      metaDescription={"Leave us a message and we'll get back to you soon as we can. We are always here to help!"}
      title={"Connect"}
      slug={'/connect'}
    />
    <ConnectRoot />
  </Layout>
)

export default ConnectPage
