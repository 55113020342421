import firebase from '@firebase/app';
import 'firebase/firestore';

var config = {
	apiKey: 'AIzaSyAWU11XJHqBxkxvbkfljwUdi8zt2aUY4JY',
	authDomain: 'dereader-7bdbf.firebaseapp.com',
	databaseURL: 'https://dereader-7bdbf.firebaseio.com',
	projectId: 'dereader-7bdbf',
	storageBucket: 'dereader-7bdbf.appspot.com',
	messagingSenderId: '229349049147'
};

if (!firebase.apps.length) {
	firebase.initializeApp(config);
}

const firestore = firebase.firestore();

export { firestore };
