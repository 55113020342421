import React from 'react';
import styled from 'styled-components';
import { Form } from 'antd';

export const StyledForm = styled(Form)`
  min-width: 450px;
  @media screen and (max-width: 451px) {
    min-width: 300px;
  }
`;

export const FormTitle = styled.h2``;
