import React from 'react';
import { firebase } from '../../firebase';
import { Form, Input, Alert, Spin, Row, Col } from 'antd';
import { FormTitle, StyledForm } from './styled';
import { Button } from '../../theme/components';
const TextArea = Input.TextArea;

class NormalEmailForm extends React.Component<
	{ form: any },
	{ error: string | undefined; success: string | undefined; loading: boolean }
> {
	constructor(props) {
		super(props);
		this.state = { error: undefined, success: undefined, loading: false };
	}
	handleSubmit = (e: any) => {
		e.preventDefault();
		this.props.form.validateFields((err: any, values: any) => {
			if (!err) {
				this.sendEmail(values.email, values.message);
				console.log('Received values of form: ', values);
				this.props.form.resetFields();
				return;
			}
		});
	};

	sendEmail = async (email: string, message: string) => {
		this.setState({
			loading: true,
			success: undefined,
			error: undefined
		});
		try {
			await firebase.firestore.collection('contactEmails').add({
				email,
				message
			});
			this.setState({
				loading: false,
				success: `Your email was successfully sent, we'll be in touch with you shortly!`,
				error: undefined
			});
		} catch (error) {
			console.log(error);
			this.setState({
				loading: false,
				success: undefined,
				error: 'Something went wrong, please refersh the page and try again.'
			});
		}
	};

	render() {
		const { getFieldDecorator } = this.props.form;
		return (
			<StyledForm onSubmit={this.handleSubmit} className='login-form'>
				<Spin spinning={this.state.loading}>
					<FormTitle>Message Us</FormTitle>
					<Form.Item>
						{getFieldDecorator('name', {
							rules: [{ required: true, message: 'Please enter your name!' }]
						})(<Input placeholder='Your Name' size="large" />)}
					</Form.Item>
					<Form.Item>
						{getFieldDecorator('email', {
							rules: [
								{
									type: 'email',
									message: 'Please enter a valid email!'
								},
								{ required: true, message: 'Please enter your email!' }
							]
						})(<Input placeholder='Your Email' size="large" />)}
					</Form.Item>
					<Form.Item>
						{getFieldDecorator('message', {
							rules: [{ required: true, message: 'Please enter your message!' }]
						})(<TextArea rows={4} placeholder='Your Message Here....' />)}
					</Form.Item>
					{this.state.error && (
						<Form.Item>
							<Alert message={this.state.error} type='error' />
						</Form.Item>
					)}
					{this.state.success && (
						<Form.Item>
							<Alert message={this.state.success} type='success' />
						</Form.Item>
					)}
					<Row type='flex' justify='end'>
						<Col span={24}>
							<Button color='primary' type="submit" style={{ width: "100%" }}>
								Send
							</Button>
						</Col>
					</Row>
				</Spin>
			</StyledForm>
		);
	}
}

export const EmailForm = Form.create({ name: 'normal_login' })(NormalEmailForm);
